import styled, { css } from 'styled-components'
import Image from 'next/image'
import {
  Box,
  Stack,
  Button,
  ButtonGroup,
  InputField,
  ButtonLink,
  Heading,
} from '@kiwicom/orbit-components'
import { Page, PageContent } from 'src/components/Page'
import Gap from 'lib/components/Gap'
import { useState } from 'react'
import { links } from 'src/links'
import { useRouter } from 'next/router'
import Row from 'lib/components/Row'
import Col from 'lib/components/Col'
import BetterText from 'src/components/BetterText'
import heroImgPath from '../../public/images/hp-hero.jpg'
import Icon from './Icon'
import BetterButtonLink from './BetterButtonLink'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  /* height: 796px; */
  height: 700px;
  max-height: 80vh;
  /* max-width: 1920px; // todo */

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  /* background: black; */
  /* TODO:  add big surr gradient based on photo!! */

  &::after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    position: absolute;
    z-index: 0;
    display: block;
  }
`

const HeroImage = styled(Image)`
  z-index: -1;
  position: relative;
`
const PageWrapper = styled.div`
  z-index: 2;
  position: relative;
`

const HpTopSection = () => {
  const router = useRouter()

  return (
    <Wrapper>
      <Image
        // src="/images/hp-hero.jpg"
        src={heroImgPath}
        //   width={1920}
        //   height={764}
        layout="fill"
        objectFit="cover"
        priority
        quality={95}
        loading="eager"
        placeholder="blur"
      />
      <PageWrapper>
        <Page>
          <PageContent>
            <Col maxWidth="600px" alignSelf="flex-start">
              <BetterText
                color="white"
                as="h1"
                fontSize="40px"
                lineHeight="44px"
                fontWeight="700"
                withShadow
              >
                Najděte ty nejlepší videotvůrce, editory a animátory
              </BetterText>
              <Gap gap="24px" />
              <Row responsive>
                <BetterButtonLink type="primary" href={links.CREATORS} size="large">
                  Najít tvůrce
                </BetterButtonLink>
                <Gap gap="16px" />
                <BetterButtonLink type="white" href={links.VIDEOS} size="large">
                  Nejnovější videoreference
                </BetterButtonLink>
              </Row>
            </Col>
            <Gap gap="76px" />
          </PageContent>
        </Page>
      </PageWrapper>
    </Wrapper>
  )
}

export default HpTopSection
