import { Heading, Loading, Text, Tooltip, Tag } from '@kiwicom/orbit-components'
import Col from 'lib/components/Col'
import Gap from 'lib/components/Gap'
import Row from 'lib/components/Row'
import { useIsMobile } from 'lib/hooks/useIsMobile'
import { inflectNounByCount } from 'lib/strings'
import Link from 'next/link'
import React, { Fragment } from 'react'

import { links } from 'src/links'
import dataFormatter, { formatCollection } from 'src/utils/dataFormatter'

import UserListItem from './UserListItem'

export function UserListHomepage({ isLoading, data, withoutTitle }) {
  const users = formatCollection(data, 'user')

  const isMobile = useIsMobile()

  const shownUsers = isMobile ? users.slice(0, 2) : users

  return (
    <Col>
      {(isLoading || !data) && <Loading />}

      {!isLoading && data && (
        <>
          {!!data.length && (
            <>
              {shownUsers?.map(user =>
                <UserListItem user={user} key={user._id} />)}
            </>
          )}
        </>
      )}
    </Col>
  )
}

function UserList({ isLoading, isEmpty, data, withoutTitle, meta }) {
  // const users = formatCollection(data, 'user')

  const { count } = meta

  return (
    <Col>
      {isLoading && <Loading />}

      {!isLoading && isEmpty && (
        <>
          <Heading type="title2">Nenalezeni žádní tvůrci</Heading>
          <Text>Upravte filtry hledání výše.</Text>
        </>
      )}

      {!!count && (
        <>
          {count && !withoutTitle && (
            <>
              <Heading type="title2">
                {inflectNounByCount(count, 'Nalezen', 'Nalezeni', 'Nalezeno')} {count}{' '}
                {inflectNounByCount(count, 'tvůrce', 'tvůrci', 'tvůrců')}
              </Heading>
              <Gap gap="16px" />
            </>
          )}

          {data?.pages?.map(page => (
            <Fragment key={page.meta.page}>
              {page.data?.map(u =>
                <UserListItem key={u._id} user={u} />)}
            </Fragment>
          ))}
        </>
      )}
    </Col>
  )
}

export default UserList
