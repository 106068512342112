import { Tag, Tooltip } from '@kiwicom/orbit-components'
import Col from 'lib/components/Col'
import Gap from 'lib/components/Gap'
import Row from 'lib/components/Row'
import Link from 'next/link'
import React, { Fragment } from 'react'
import { links } from 'src/links'
import dataFormatter, { formatCollection } from 'src/utils/dataFormatter'
import styled from 'styled-components'
import { QuestionCircle } from '@kiwicom/orbit-components/icons'
import BetterLink from './BetterLink'
import BetterText from './BetterText'
import ContentBox from './ContentBox'
import OverflowWrapper from './OverflowWrapper'
import ProfileImage from './ProfileImage'
import SmallVideoCard from './SmallVideoCard'

const UserListItem = ({ user }) => {
  // console.log('user', user)
  user = !user.original ? dataFormatter(user, 'user') : user

  const videos = formatCollection(user.videos.slice(0, 3), 'video')

  return (
    <Fragment key={user._id}>
      <ContentBox padding="16px">
        <Row alignItems="center" justifyContent="space-between" responsive>
          <Row alignItems="center" shrink="0" responsive maxWidth="430px" width="100%">
            <Link
              href={{
                pathname: links.CREATOR_PUBLIC_DETAIL,
                query: { slug: user.slug },
              }}
            >
              <ProfileImage user={user} size="122px" mobileSize="96px" pointer withIcon />
            </Link>
            <Gap gap="16px" />
            <Col
              grow="0"
              // maxWidth="240px" width="100%"
              mobileMaxWidth="300px"
              maxWidth="none"
            >
              <BetterLink.Pure
                href={{
                  pathname: links.CREATOR_PUBLIC_DETAIL,
                  query: { slug: user.slug },
                }}
              >
                <BetterText
                  pointer
                  fontSize="20px"
                  fontWeight="500"
                  mobileTextAlign="center"
                  textAlign="left"
                >
                  {user.name}
                </BetterText>
              </BetterLink.Pure>
              <Gap gap="4px" />

              <BetterText
                mobileTextAlign="center"
                textAlign="left"
                fontSize="14px"
                lineHeight="20px"
              >
                {user.region}
              </BetterText>
              <Gap gap="8px" />
              <Row alignItems="flex-start">
                <Tooltip
                  placement="top"
                  content={user.skills.map(item =>
                    <div key={item}>{item}</div>)}
                >
                  <Tag size="small">Co umím? ({user.skills.length})</Tag>
                </Tooltip>
                <Gap gap="8px" />
                <Tooltip
                  placement="top"
                  content={(
                    <div>
                      {user.videoTypes.map(item =>
                        <div key={item}>{item}</div>)}
                    </div>
                  )}
                >
                  <Tag size="small">Jaká videa? ({user.videoTypes?.length})</Tag>
                </Tooltip>

                {/* <div>
                  {user.videoTypes.map(item =>
                    <Tag size="small">{item}</Tag>)}
                </div> */}
                {/* <Gap gap="8px" />
                <Tooltip placement="top" content={<div>asdf</div>}>
                  <Tag size="small">
                    {user.original.isCompany ? 'Tým' : 'Jednotlivec'}
                  </Tag>
                </Tooltip> */}
              </Row>

              {/* <EllipsesWrapper>
                <Text>
                  {user.skills.slice(0, skillsShown).join(', ')}
                  {moreSkills ? (
                    <Tooltip
                      preferredPosition="top"
                      content={user.skills.slice(skillsShown).join(', ')}
                    >
                      &nbsp;a&nbsp;
                      {inflectNounByCount(
                        user.skills.length - skillsShown,
                        'další',
                        'další',
                        'dalších',
                      )}
                    </Tooltip>
                  )
                    : ''}{' '}
                </Text>
              </EllipsesWrapper>

              <EllipsesWrapper>
                <Text>
                  {user.videoTypes.slice(0, typesShown).join(', ')}
                  {moreVideoTypes ? (
                    <Tooltip
                      preferredPosition="top"
                      content={user.videoTypes.slice(typesShown).join(', ')}
                    >
                      &nbsp;a&nbsp;
                      {inflectNounByCount(
                        user.videoTypes.length - typesShown,
                        'další',
                        'další',
                        'dalších',
                      )}
                      &nbsp;{user.videoTypes.length - typesShown}
                    </Tooltip>
                  )
                    : '---'}{' '}
                </Text>
              </EllipsesWrapper> */}
            </Col>
          </Row>
          <Gap gap="8px" mobileGap="16px" />

          <OverflowWrapper mobileMaskSize="0px">
            <Row
              mobileWrap="wrap"
              wrap="nowrap"
              maxWidth="920px"
              mobileOverflowX="initial"
              overflowX="auto"
              overflowY="hidden"
              mobileJustifyContent="center"
              justifyContent="flex-start"
              mobileMargin="0 0 0"
              margin="0 -8px 0 auto"
              mobileWidth="100%"
              width="auto"
              width="fit-content"
            >
              {videos.map((video, index) => (
                <SmallVideoCard
                  key={video._id}
                  margin="0 0 8px 8px"
                  mobileMargin="0 0 8px 0"
                  video={video}
                  user={user}
                />
              ))}
            </Row>
          </OverflowWrapper>
        </Row>
      </ContentBox>
      <Gap gap="24px" />
    </Fragment>
  )
}

export default UserListItem

// const EllipsesWrapper = styled.div`
//   /* width: 100%; */
//   max-height: 20px;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   flex-grow: 0;
//   max-width: 100%;
// `
