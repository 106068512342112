import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player'
import { Button, Loading, Stack, Text, Tooltip } from '@kiwicom/orbit-components'
import Col from 'lib/components/Col'
import Gap from 'lib/components/Gap'
import Row from 'lib/components/Row'
import { useRef, useState } from 'react'
import { links } from 'src/links'
import { useDeleteVideoMutation } from 'src/queries/myVideos'
import { up } from 'lib/styles'
import Icon from './Icon'
import BetterLink from './BetterLink'
import ProfileImage from './ProfileImage'
import DeleteDialog from './DeleteDialog'
import { InfoTag, InfoTagWrapper } from './VideoCard'
import { useVideoDetail } from './VideoDetail'

const ThumbWrapper = styled.div`
  ${({ margin = '0 0 0 8px', mobileMargin = '0 0 8px 0' }) => css`
    cursor: pointer;
    border-radius: 3px;
    width: 220px;
    height: 122px;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: black;
    position: relative;
    flex-shrink: 0;

    width: 300px;
    height: 166px;
    max-width: 100%;
    margin: ${mobileMargin || margin};
    ${up('mobile')} {
      margin: ${margin};
      width: 220px;
      height: 122px;
    }

    img {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
      max-width: 100%;
    }

    &:hover ${InfoTagWrapper} {
      opacity: 1 !important;
    }
  `}
`

const SmallVideoCard = ({ video, user, margin, mobileMargin }) => {
  const { showDetail, renderDetail } = useVideoDetail({ video, user })

  // console.log('video, user', video, user)
  return (
    <>
      {renderDetail()}
      <ThumbWrapper
        margin={margin}
        mobileMargin={mobileMargin}
        onClick={() => {
          // console.log('aSDFASDF')
          showDetail()
        }}
      >
        <img src={video.thumbnail} alt="video thumbnail" loading="lazy" />

        <InfoTag top="6px" left="6px" tooltip="Typ videa" show={false}>
          {video.videoType}
        </InfoTag>
        {video.budget && (
          <InfoTag top="6px" right="6px" tooltip="Hrubý rozpočet za práci tvůrce" show={false}>
            {video.budget}
          </InfoTag>
        )}
        {/* <InfoTag bottom="10px" left="10px" tooltip="Předvedené dovednosti">
        {video.skills.join(', ')}
        {video.skills.join(', ')}
        {video.skills.join(', ')}
        {video.skills.join(', ')}
        {video.skills.join(', ')}
        {video.skills.join(', ')}
      </InfoTag> */}
      </ThumbWrapper>
    </>
  )
}
export default SmallVideoCard
