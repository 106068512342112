import { Heading, Loading, Text } from '@kiwicom/orbit-components'
import Col from 'lib/components/Col'
import Gap from 'lib/components/Gap'
import { useIsMobile } from 'lib/hooks/useIsMobile'
import { inflectNounByCount } from 'lib/strings'
import React, { Fragment, useState } from 'react'
import { formatCollection } from 'src/utils/dataFormatter'
import VideoCard, { VideosWrapper } from './VideoCard'

export const VideoListHomepage = ({ data }) => {
  const videos = formatCollection(data, 'video')

  const isMobile = useIsMobile()

  const shownVideos = isMobile ? videos.slice(0, 3) : videos

  return (
    <VideosWrapper justify="center">
      {shownVideos?.map(v =>
        <VideoCard video={v} key={v._id} user={v.author} />)}
    </VideosWrapper>
  )
}

function VideoList({ isLoading, isEmpty, data, withoutTitle, meta }) {
  // const formattedPages = data?.pages?.map((page, index) => formatCollection(page.data, 'video'))

  // const videos = formatCollection(data, 'video')

  // console.log('formattedPages', formattedPages)

  const { count } = meta
  // console.log('data', data, count)

  return (
    <Col>
      <>
        {/* isLoading is firstLoad , not next pages load */}
        {isLoading && <Loading />}
        {!isLoading && isEmpty && (
          <>
            <Heading type="title2">Nenalezena žádná videa</Heading>
            <Text>Upravte filtry hledání výše.</Text>
          </>
        )}
        {!!count && (
          <>
            {count && !withoutTitle && (
              <>
                <Heading type="title2">
                  {inflectNounByCount(count, 'Nalezeno', 'Nalezena', 'Nalezeno')} {count}{' '}
                  {inflectNounByCount(count, 'video', 'videa', 'videí')}
                </Heading>
                <Gap gap="16px" />
              </>
            )}
            <VideosWrapper justify="center">
              {data?.pages?.map(page => (
                <Fragment key={page.meta.page}>
                  {page.data?.map(v =>
                    <VideoCard video={v} key={v._id} user={v.author} />)}
                </Fragment>
              ))}
            </VideosWrapper>
          </>
        )}
      </>
    </Col>
  )
}

export default VideoList
