import { up } from 'lib/styles'
import React from 'react'
import styled, { css } from 'styled-components'

const OverflowWrapper = styled.div`
  ${({ maskSize = '24px', mobileMaskSize = '24px' }) => css`
    /* scroll bar width, for use in mask calculations */
    --scrollbar-width: 0px;

    /* mask fade distance, for use in mask calculations */
    /* --mask-height: 32px; */
    
    --mask-height: ${mobileMaskSize};
    ${up('mobile')} {
      --mask-height: ${maskSize};
    }

    /* If content exceeds height of container, overflow! */
    /* overflow-y: auto; */

    /* Our height limit */
    /* height: 300px; */

    /* Need to make sure container has bottom space,
  otherwise content at the bottom is always faded out */
    padding: 0 var(--mask-height);

    /* Keep some space between content and scrollbar */
    /* padding-right: 20px; */

    /* The CSS mask */

    /* The content mask is a linear gradient from top to bottom */
    --mask-image-content: linear-gradient(
      to right,
      transparent,
      black var(--mask-height),
      black calc(100% - var(--mask-height)),
      transparent
    );

    /* Here we scale the content gradient to the width of the container 
  minus the scrollbar width. The height is the full container height */
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

    /* The scrollbar mask is a black pixel */
    --mask-image-scrollbar: linear-gradient(black, black);

    /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
    --mask-size-scrollbar: var(--scrollbar-width) 100%;

    /* Apply the mask image and mask size variables */
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

    /* Position the content gradient in the top left, and the 
  scroll gradient in the top right */
    mask-position: 0 0, 100% 0;

    /* We don't repeat our mask images */
    mask-repeat: no-repeat, no-repeat;
    overflow: auto;
    width: 100%;
  `}
`

export default OverflowWrapper
