import Row from 'lib/components/Row'
import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import Gap from 'lib/components/Gap'
import Col from 'lib/components/Col'
import { Stack, Text } from '@kiwicom/orbit-components'
import Image from 'next/image'

import { up } from 'lib/styles'
import img1 from '../../public/images/testimonials/1.jpg'
import img2 from '../../public/images/testimonials/2.jpg'
import img3 from '../../public/images/testimonials/3.jpg'

const data = [
  {
    imgSrc: img2,
    arrowUp: true,

    name: 'David Hanák',
    subtitle: 'HR, Avast',
    content:
      'Chtěli jsem najít šikovného kameramana pro prezentaci nového produktu a díky videotvurci.cz jsme ho našli behěm chvíle. Podle zkušeností a specializace jsme si našli dokonce člověka na dlouhodobu spolupráci.',
  },
  {
    imgSrc: img1,
    arrowUp: false,

    name: 'Zuzanna Jacewicz',
    subtitle: 'Web Content Specialist, Mladiinfo Czech Republic',
    content:
      'Hledala jsem dlouho pro svůj projekt někoho, kdo odprezentuje můj produkt v podobě videa a videotvurci.cz mi zjednodušili hledání, vše jsem našla na jednom místě. Doporučuji.',
  },
  {
    imgSrc: img3,
    arrowUp: true,
    name: 'Ondřej Zeman',
    subtitle: 'Project Manažer, Barclays',
    content:
      'Na menší kampaň jsme neměli rozpočet a čas najmout větší produkční společnost, ale chtěli jsme jenom videotvůrce na prezentaci našeho oddělení. Na portálu jsme si vybrali z několika tvůrců během několika hodin a spolupráce předčila očekávání. Určitě zase využijeme služeb videotvurcu.cz.',
  },
]

const Bubble = styled.div`
  ${({ arrowUp, theme: { colors } }) => css`
    padding: 24px 16px;
    background: ${colors.cloudLight};
    border-radius: 8px;
    border: 1px solid #e8edf1;
    position: relative;

    margin: 0 0 12px;
    ${up('mobile')} {
      margin: ${arrowUp ? '12px 0 0 0' : '0 0 12px'};
    }

    &::after {
      content: '';
      left: 50%;
      width: 16px;
      height: 16px;
      background: ${colors.cloudLight};
      border: solid #e8edf1;

      /* background: red; */
      transform: translateX(-50%) rotate(45deg);
      position: absolute;
      /* z-index: 0; */
      display: block;

      border-width: 0 1px 1px 0;
      bottom: -8px;

      ${up('mobile')} {
        border-width: ${arrowUp && '1px 0 0 1px'};
        border-width: ${!arrowUp && '0 1px 1px 0'};
        top: auto;
        top: ${arrowUp && '-8px'};
        bottom: ${!arrowUp && '-8px'};
      }
    }
  `}
`

const FaceWrapper = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 300px;
  overflow: hidden;

  /* img {
    width: 100%;
    height: 100%;
  } */
`

const Testimonial = ({ index, data: { name, subtitle, content, arrowUp, imgSrc } }) => (
  <Col
    maxWidth="340px"
    align="center"
    direction={arrowUp ? 'column' : 'column-reverse'}
    mobileDirection="column-reverse"
  >
    <FaceWrapper>
      <Image src={imgSrc} alt="" width="150px" height="150px" placeholder="blur" />
    </FaceWrapper>
    <Gap gap="6px" />
    <Bubble arrowUp={arrowUp}>
      <Col>
        <Text size="large">{content}</Text>
        <Gap gap="16px" />
        <Text weight="bold">{name}</Text>
        <Text>{subtitle}</Text>
      </Col>
    </Bubble>
    {index === 1 && <Gap gap="56px" mobileGap="0" />}
    {index !== 0 && <Gap gap="0" mobileGap="32px" />}
  </Col>
)

function Testimonials() {
  return (
    <Col width="100%" maxWidth="1180px" alignItems="stretch" alignSelf="center">
      <Row justifyContent="space-between" responsive mobileAlignItems="center">
        {data.map((t, index) => (
          <Fragment key={index}>
            {index !== 0 && <Gap gap="16px" />}
            <Testimonial key={index} index={index} data={t} />
          </Fragment>
        ))}
      </Row>
    </Col>
  )
}

export default Testimonials
