import Row from 'lib/components/Row'
import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import Gap from 'lib/components/Gap'
import Col from 'lib/components/Col'
import { Heading, Stack, Text } from '@kiwicom/orbit-components'
import Image from 'next/image'
import icon1 from '../../public/images/icons/how-1.svg'
import icon2 from '../../public/images/icons/how-2.svg'
import icon3 from '../../public/images/icons/how-3.svg'

const data = [
  {
    title: 'Najdete tvůrce',
    iconSrc: icon1,
    content:
      'Mezi tvůrci můžete filtrovat třeba podle dovedností a nebo můžete procházet videa podobná tomu, co chcete vyrobit.',
  },
  {
    title: 'Osloví te ho/jí',
    iconSrc: icon2,
    content:
      'Tvůrci napíšete jednoduchou zprávu ohledně vašeho projektu. Nebojte, připravili jsme pro vás tipy, jak nejlépe zadat projekt.',
  },
  {
    title: 'A jde se tvořit',
    iconSrc: icon3,
    content: 'Dohodnete se a jdete na věc. Po skončení práce pak tvůrce můžete ohodnotit.',
  },
]

const HowIcon = styled(Image)`
  transform: scale(1.5);
`

const ImageWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    width: 150px;
    height: 150px;
    border-radius: 300px;
    overflow: hidden;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    color: ${colors.iconPrimary};
  `}
`

const Step = ({ index, data: { title, content, iconSrc } }) => (
  <Col maxWidth="220px" alignItems="center">
    <ImageWrapper>
      <HowIcon src={iconSrc} />
    </ImageWrapper>
    <Gap gap="16px" />
    <Col alignItems="center">
      <Heading type="title3">{title}</Heading>
      <Gap gap="16px" />
      <Text align="center" size="large">
        {content}
      </Text>
    </Col>
  </Col>
)

function HowItWorks() {
  return (
    <Col width="100%" maxWidth="920px" alignItems="stretch" alignSelf="center">
      <Row
        justifyContent="space-between"
        responsive
        mobileAlignItems="center"
        alignItems="flex-start"
      >
        {data.map((t, index) => (
          <Fragment key={index}>
            {index !== 0 && <Gap gap="16px" mobileGap="48px" />}
            <Step key={index} index={index} data={t} />
          </Fragment>
        ))}
      </Row>
    </Col>
  )
}

export default HowItWorks
